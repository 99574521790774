import _ from 'lodash'
import moment from 'moment'
import { TimeslotDefaults } from '@sevenrooms/core/domain'
import * as ActionTypes from 'mgr/actualslideout/actions/ActionTypes'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { reduceUserDomainVenueAttrs } from 'mgr/lib/utils/AppStateUtils'
import { pickBestShift } from 'mgr/lib/utils/ShiftUtils'
import { getVenueToday } from 'svr/common/TimeUtil'
import { createAvailabilityTimeslotFromAccessRule, mixTimeslot } from '../components/availability/createARTimeslotHelper'
import { hasChangedCriteriaFromLastSave } from '../components/availability/helpers'

const resetTimesNewState = (isMultiVenueSearch, isLoading, isError) => ({
  availableTimes: [],
  internalCutoff: '',
  isMultiVenueSearch,
  isTimesLoading: isLoading,
  isTimesLoadError: isError,
})

const DEFAULT_DURATION = 0

const getDefaultDurationForPartySizeAndTime = (shift, partySize, timeSlot) => {
  // a bug? timeSlot param used here as a number, but in function calls below passed an object
  if (!_.isObject(shift)) {
    return DEFAULT_DURATION
  }
  if (_.isNil(timeSlot)) {
    // eslint-disable-next-line no-param-reassign
    timeSlot = shift.start_order
  }
  const earlierStartOrders = _.filter(_.keys(shift.duration_ranges_by_order), order => order <= timeSlot)
  const selectedStartOrder = earlierStartOrders ? Math.max(earlierStartOrders) : -1
  const durationDict = shift.duration_ranges_by_order[selectedStartOrder] || shift.duration_minutes_by_party_size

  if (_.isNil(partySize)) {
    // eslint-disable-next-line no-param-reassign
    partySize = 0
  }
  if (partySize === 9 && _.isUndefined(durationDict[9])) {
    // legacy bug where 7-9 range was not added to 9th index properly
    // eslint-disable-next-line no-param-reassign
    partySize = 8
  }
  return durationDict[partySize] || durationDict[-1]
}

const getDefaultDuration = (shift, partySize) =>
  Object.keys(shift?.duration_ranges_by_order ?? {}).length === 1
    ? getDefaultDurationForPartySizeAndTime(shift, partySize, null)
    : DEFAULT_DURATION

const getShiftBufferDetails = shift => {
  const { buffer_minutes_by_party_size: bufferMinutesByPartySize } = shift
  const hasBufferMinutesByPartySize = bufferMinutesByPartySize && Object.keys(bufferMinutesByPartySize).length > 0
  const shiftBufferMins = hasBufferMinutesByPartySize ? bufferMinutesByPartySize[Object.keys(bufferMinutesByPartySize)[0]] : 0
  const shiftBufferPartySizeMin = hasBufferMinutesByPartySize ? parseInt(Object.keys(bufferMinutesByPartySize)[0]) : 0
  return { shiftBufferMins, shiftBufferPartySizeMin }
}

const getChangePartySizeResetState = partySize => ({
  partySize,
  usingDefaultDuration: true,
  availabilityByVenue: {},
  selectedTimeSlot: null,
})

export const initialState = {
  // Init Data should not be in initState so that it doesn't get cleared on reset
  // initialDate, initialVenue
  searchVenues: [],
  searchResultsPage: 0,
  resultsPerPage: 7,
  date: moment(),
  partySize: 2,
  shiftPersistentId: null,
  searchTimeSlot: 52, // 7pm
  seatingAreaId: null,
  isMultiVenueSeatingAreaSearchAny: false, // Default is to search 'Default' seating area (as opposed to 'Any' seating area)
  duration: 0,
  defaultDuration: 0,
  usingDefaultDuration: true,
  // In modify, if editing a reservation that had duration selected in widget duration picker, don't reset to shift default
  isDurationPicked: false,
  preserveDurationOnInitialLoad: false,
  shifts: [],
  shiftsByPersistentId: {},
  floorPlan: {
    seatingAreaCodesToTables: {},
    tableItems: [],
    seatingAreas: [],
    seatingAreasByTableId: {},
    seatingAreasToTablesBySeatingAreaId: {},
    tableItemsByTableId: {},
    seatingAreasById: {},
  },
  availabilityByVenue: {},
  selectedTimeSlot: null,
  preselectedSortOrder: null,
  preselectedTableId: null,
  previousSelectedTimeSlot: null,
  dailyEventsByVenueDate: {},
  showAccessRules: false,
  audienceId: null,
  typeId: TimeslotDefaults.ALL,
  actual: null,
  bufferMins: 0,
  shiftBufferMins: 0,
  shiftBufferPartySizeMin: 0,
  gettingReservationHold: false,
  excludeFromShiftPacing: false,
  lockToAvoidGettingResHoldWhileLoading: false,
  experiences: [],
  previousPartySize: 2,
  previousDuration: 0,
  previousDate: moment(),
}

const bookAvailabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GlobalActionTypes.INITIALIZE:
      return {
        ...state,
        initialDate: action.globalInit.dateSelected,
        date: action.globalInit.dateSelected,
        previousDate: action.globalInit.dateSelected,
        internalArBookingEnabled: action.globalInit.venueSettings.internal_ar_booking_enabled,
        availabilityLockInternalBookingEnabled: action.globalInit.venueSettings.availability_lock_internal_booking_enabled,
      }
    case GlobalActionTypes.ON_USER_DOMAIN_VENUES_LOAD: {
      // eslint-disable-next-line no-undef
      const initialUserDomainVenue = _.find(Pmp.Manager.Global.UserDomainVenues.venues, { id: window.globalInit.venueId })
      return {
        ...state,
        initialVenue: reduceUserDomainVenueAttrs(initialUserDomainVenue),
      }
    }
    case ActionTypes.ENTER_ADD_RESERVATION: {
      const { initialDate, initialVenue } = state
      const searchVenues = [initialVenue]
      const { timezone, startOfDayHour } = initialVenue
      const today = getVenueToday(timezone, startOfDayHour)
      let date = action.date || initialDate || state.date
      if (date.isBefore(today)) {
        date = today
      }
      const shiftPersistentId = action.shiftPersistentId || null
      const partySize = _.isNumber(action.partySize) ? action.partySize : initialState.partySize
      const preselectedSortOrder = _.isNumber(action.sortOrder) ? action.sortOrder : null
      const preselectedTableId = action.tableId
      const searchTimeSlot = _.isNumber(preselectedSortOrder) ? preselectedSortOrder : initialState.searchTimeSlot
      const seatingAreaId = action.tableId ? action.seatingAreaId : initialVenue.bookSettings.defaultSeatingAreaId
      const typeId = action.typeId || state.typeId
      const showAccessRules = window.localStorage.getItem('show-access-rules') === 'true'
      return {
        ...state,
        ...initialState,
        searchVenues,
        date,
        previousDate: date,
        shiftPersistentId,
        partySize,
        previousPartySize: partySize,
        seatingAreaId,
        preselectedSortOrder,
        preselectedTableId,
        searchTimeSlot,
        preserveDurationOnInitialLoad: false,
        showAccessRules,
        typeId,
      }
    }
    case ActionTypes.ENTER_CLONE_RESERVATION: {
      return { ...state, selectedTimeSlot: null, preselectedSortOrder: null }
    }
    case ActionTypes.COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS: {
      // View/edit
      const { actual, venue } = action
      const searchVenues = [venue]
      const date = actual.date_moment
      const shiftPersistentId = actual.shift_persistent_id
      const partySize = actual.max_guests
      const preselectedSortOrder = actual.arrival_time_sort_order
      const searchTimeSlot = preselectedSortOrder
      let duration = actual.using_default_duration ? 0 : actual.duration
      const isDurationPicked = actual.is_duration_picked
      const seatingAreaId = actual.venue_seating_area_id
      const usingDefaultDuration = !!actual.using_default_duration
      const shiftCategory = state.shiftsByPersistentId?.[shiftPersistentId]?.category
      let previousSelectedTimeSlot
      if (state.internalArBookingEnabled && actual.access_rule) {
        previousSelectedTimeSlot = mixTimeslot(createAvailabilityTimeslotFromAccessRule(actual.access_rule, actual, venue), shiftCategory)
        duration = previousSelectedTimeSlot.duration
      }
      const bufferMins = window.globalInit.venueSettings.is_buffers_enabled ? actual.buffer_mins : 0
      const channel = actual.channel ?? actual.access_rule?.audience_tiers[0]?.channels[0]
      const audienceId = state.internalArBookingEnabled ? channel : undefined
      return {
        ...state,
        ...initialState,
        searchVenues,
        date,
        previousDate: date,
        shiftPersistentId,
        partySize,
        previousPartySize: partySize,
        preselectedSortOrder,
        searchTimeSlot,
        duration,
        previousDuration: duration,
        isDurationPicked,
        seatingAreaId,
        usingDefaultDuration,
        previousSelectedTimeSlot,
        audienceId,
        showAccessRules: state.internalArBookingEnabled && !!actual.access_rule,
        preserveDurationOnInitialLoad: true,
        actual,
        bufferMins,
        excludeFromShiftPacing: actual.exclude_from_shift_pacing,
        lockToAvoidGettingResHoldWhileLoading: state.lockToAvoidGettingResHoldWhileLoading,
        typeId: actual.experience_id ? actual.experience_id : initialState.typeId,
      }
    }
    case ActionTypes.BOOK_CHANGE_SEARCH_VENUES: {
      const { isMultiVenueSeatingAreaSearchAny } = state
      const { searchVenues, shiftPersistentId } = action
      const isChangeBetweenSingleAndMultiSearch = searchVenues.length === 1 || state.searchVenues.length === 1
      const availabilityByVenue = isChangeBetweenSingleAndMultiSearch ? {} : state.availabilityByVenue
      const searchResultsPage = 0
      const seatingAreaId =
        searchVenues.length === 1 && !isMultiVenueSeatingAreaSearchAny ? searchVenues[0].bookSettings.defaultSeatingAreaId : null
      const selectedTimeSlot =
        _.isNil(state.selectedTimeSlot) || !_.some(searchVenues, v => v.id === state.selectedTimeSlot.venue_id)
          ? null
          : state.selectedTimeSlot
      return {
        ...state,
        searchVenues,
        shiftPersistentId,
        availabilityByVenue,
        searchResultsPage,
        seatingAreaId,
        selectedTimeSlot,
        shifts: [],
        shiftsByPersistentId: {},
        typeId: TimeslotDefaults.ALL,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SEARCH_RESULTS_PAGE: {
      const { searchResultsPage } = action
      return { ...state, searchResultsPage }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_DATE: {
      const isMultiVenueSearch = state.searchVenues.length > 1
      // If only one venue, give illusion of searching immediately, instead of waiting for shifts to load
      const availabilityByVenue = isMultiVenueSearch
        ? {}
        : {
            [state.searchVenues[0].id]: {
              ...resetTimesNewState(isMultiVenueSearch, true, false),
            },
          }
      return {
        ...state,
        date: action.date,
        previousDate: state.date,
        selectedTimeSlot: null,
        shifts: [],
        usingDefaultDuration: true,
        availabilityByVenue,
        previousSelectedTimeSlot: state.availabilityLockInternalBookingEnabled ? null : state.previousSelectedTimeSlot,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_PARTY_SIZE: {
      const isMultiVenue = state.searchVenues.length > 1
      if (isMultiVenue) {
        return { ...state, ...getChangePartySizeResetState(action.partySize) }
      }
      const shift = state.shiftsByPersistentId[state.shiftPersistentId]

      let newBufferMins = state.bufferMins
      if (action.partySize <= state.shiftBufferPartySizeMin) {
        newBufferMins = 0
      } else if (state.partySize <= state.shiftBufferPartySizeMin && action.partySize > state.shiftBufferPartySizeMin) {
        newBufferMins = state.shiftBufferMins
      }

      return {
        ...state,
        ...getChangePartySizeResetState(action.partySize),
        previousPartySize: state.partySize,
        duration: state.isDurationPicked ? state.duration : getDefaultDuration(shift, action.partySize),
        previousDuration: state.duration,
        defaultDuration: getDefaultDurationForPartySizeAndTime(shift, action.partySize, state.selectedTimeSlot),
        bufferMins: newBufferMins,
        selectedTimeSlot: state.availabilityLockInternalBookingEnabled ? null : state.selectedTimeSlot,
        previousSelectedTimeSlot: state.availabilityLockInternalBookingEnabled ? null : state.previousSelectedTimeSlot,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SHIFT: {
      const { shiftBufferMins, shiftBufferPartySizeMin } = getShiftBufferDetails(action.shift)

      let newBufferMins = shiftBufferMins
      if (state.partySize <= shiftBufferPartySizeMin) {
        newBufferMins = 0
      }

      return {
        ...state,
        shiftPersistentId: action.shift.persistent_id,
        duration: getDefaultDuration(action.shift, state.partySize),
        previousDuration: state.duration,
        defaultDuration: getDefaultDurationForPartySizeAndTime(action.shift, state.partySize, state.selectedTimeSlot),
        selectedTimeSlot: null,
        usingDefaultDuration: true,
        shiftBufferMins,
        shiftBufferPartySizeMin,
        bufferMins: window.globalInit.venueSettings.is_buffers_enabled ? newBufferMins : 0,
        previousSelectedTimeSlot: state.availabilityLockInternalBookingEnabled ? null : state.previousSelectedTimeSlot,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SEARCH_TIME_SLOT: {
      const { searchTimeSlot } = action
      return {
        ...state,
        searchTimeSlot,
        availabilityByVenue: {},
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_DURATION:
      return {
        ...state,
        duration: action.duration,
        previousDuration: state.duration,
        availabilityByVenue: {},
        selectedTimeSlot: null,
        isDurationPicked: false,
        usingDefaultDuration: action.duration === 0,
        previousSelectedTimeSlot: state.availabilityLockInternalBookingEnabled ? null : state.previousSelectedTimeSlot,
      }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SEATING_AREA: {
      const { seatingArea } = action
      const seatingAreaId = _.isString(seatingArea) ? null : seatingArea.id
      const isMultiVenueSeatingAreaSearchAny = seatingArea === '_any_'
      return {
        ...state,
        seatingAreaId,
        isMultiVenueSeatingAreaSearchAny,
        availabilityByVenue: {},
        selectedTimeSlot: null,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SHOW_ACCESS_RULES: {
      const { showAccessRules } = action
      window.localStorage.setItem('show-access-rules', showAccessRules)
      return {
        ...state,
        showAccessRules,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_AUDIENCE: {
      const { audienceId } = action
      return {
        ...state,
        audienceId,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_TYPE: {
      const { typeId } = action
      return {
        ...state,
        typeId,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_SET_EXPERIENCES: {
      const { experiences } = action
      return {
        ...state,
        experiences,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT: {
      const { selectedTimeSlot, isPreviousTime, lockToAvoidGettingResHoldWhileLoading, source, isDirectlySelected } = action
      const previousSelectedTimeSlot = selectedTimeSlot
      let { shiftPersistentId, duration } = state
      if (!isPreviousTime) {
        // If conducting multi-venue search, don't populate shift/duration search fields on auto-reselection of time
        shiftPersistentId = selectedTimeSlot.shift_persistent_id
        duration = selectedTimeSlot.duration
      }

      // Inherit the exclude from shift pacing flag from the AR, but only if it is changing - we don't want to overwrite
      // user's selection. We hit this action when first loading on edit mode too though.
      const excludeFromShiftPacing =
        state.selectedTimeSlot !== undefined && selectedTimeSlot?.access_persistent_id !== state.selectedTimeSlot?.access_persistent_id
          ? Boolean(selectedTimeSlot.exclude_from_shift_pacing)
          : state.excludeFromShiftPacing

      let { previousDuration, previousPartySize } = state
      if (isDirectlySelected) {
        previousDuration = duration
        previousPartySize = state.partySize
      }

      const result =
        source === 'getTimeSelectionIfAvailable' ? state.lockToAvoidGettingResHoldWhileLoading : lockToAvoidGettingResHoldWhileLoading
      return {
        ...state,
        selectedTimeSlot,
        previousSelectedTimeSlot,
        shiftPersistentId,
        duration,
        previousDuration,
        previousPartySize,
        excludeFromShiftPacing,
        lockToAvoidGettingResHoldWhileLoading: result,
      }
    }
    case ActionTypes.ENABLE_LOCK_TO_AVOID_GETTING_A_NEW_RES_HOLD:
      return {
        ...state,
        lockToAvoidGettingResHoldWhileLoading: true,
      }
    case ActionTypes.CLOSE_SLIDEOUT:
      return {
        ...state,
        lockToAvoidGettingResHoldWhileLoading: false,
      }
    case ActionTypes.BOOK_AVAILABILITY_HOLD_RESERVATION_START: {
      return {
        ...state,
        expirationMoment: null,
        errorMessageForHoldReservationFailure: null,
        gettingReservationHold: true,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_HOLD_RESERVATION_SUCCESS: {
      const {
        reservationHoldId,
        holdDurationSeconds,
        previousHoldResDate,
        previousHoldDuration,
        previousHouldPartySize,
        previousHoldTime,
        previousHoldVenueId,
        previousHoldAccessPersistentId,
        previousHoldShiftPersistentId,
      } = action
      const expirationMoment = moment().add(holdDurationSeconds, 'second')
      return {
        ...state,
        reservationHoldId,
        holdDurationSeconds,
        previousHoldResDate,
        previousHoldDuration,
        previousHouldPartySize,
        previousHoldTime,
        previousHoldVenueId,
        previousHoldAccessPersistentId,
        previousHoldShiftPersistentId,
        expirationMoment,
        gettingReservationHold: false,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_HOLD_RESERVATION_FAILURE: {
      const { errorMessageForHoldReservationFailure, logLevel } = action
      const expirationMoment = moment().add(59, 'second')
      return {
        ...state,
        errorMessageForHoldReservationFailure,
        expirationMoment,
        logLevel: logLevel || 'error',
        gettingReservationHold: false,
      }
    }
    case ActionTypes.GET_SUCCESS_WIDGET_SETTINGS: {
      const { widgetSettings } = action
      return {
        ...state,
        reservationHoldEnabled: widgetSettings.reservationHoldEnabled,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_RELEASE_RESERVATION_HOLD_SUCCESS: {
      return {
        ...state,
        reservationHoldId: null,
        holdDurationSeconds: null,
        previousHoldResDate: null,
        previousHoldDuration: null,
        previousHouldPartySize: null,
        previousHoldTime: null,
        previousHoldVenueId: null,
        previousHoldAccessPersistentId: null,
        previousHoldShiftPersistentId: null,
        expirationMoment: null,
        gettingReservationHold: false,
        errorMessageForHoldReservationFailure: null,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_GET_SHIFTS_SUCCESS: {
      const { venue, shifts } = action
      const shiftsByPersistentId = {}
      for (const s of shifts) {
        shiftsByPersistentId[s.persistent_id] = s
      }

      let prevShift

      // If this is the initial load and there is no shift cache,
      // try to see if there is a shift that was just sent up
      // that matches the persistent ID.
      // Point being only new reservations produce a null
      // prevShift. Edit's must not.
      if (_.isEmpty(state.shiftsByPersistentId)) {
        prevShift = shiftsByPersistentId[state.shiftPersistentId]
      } else {
        prevShift = state.shiftsByPersistentId[state.shiftPersistentId]
      }
      const shift =
        pickBestShift(venue, shifts, prevShift, {
          loadNextBookableShiftForDate: state.date,
        }) || {}

      const { shiftBufferMins, shiftBufferPartySizeMin } = getShiftBufferDetails(shift)
      const shiftPersistentId = shift.persistent_id
      const defaultDuration = getDefaultDurationForPartySizeAndTime(shift, state.partySize, state.selectedTimeSlot)

      let newBufferMins = shiftBufferMins
      if (state.partySize <= shiftBufferPartySizeMin) {
        newBufferMins = 0
      }

      return {
        ...state,
        shifts,
        shiftsByPersistentId,
        shiftPersistentId,
        duration:
          state.isDurationPicked || state.preserveDurationOnInitialLoad ? state.duration : getDefaultDuration(shift, state.partySize),
        previousDuration: state.duration,
        defaultDuration,
        preserveDurationOnInitialLoad: false,
        shiftBufferMins,
        shiftBufferPartySizeMin,
        bufferMins: window.globalInit.venueSettings.is_buffers_enabled && !state.actual ? newBufferMins : state.bufferMins,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_GET_SHIFTS_FAIL:
      return {
        ...state,
        shifts: [],
        shiftsByPersistentId: {},
        shiftPersistentId: null,
      }
    case ActionTypes.BOOK_AVAILABILITY_GET_DAILY_EVENTS_SUCCESS: {
      const { venueId, date, events, note } = action
      const newState = { ...state }
      newState.dailyEventsByVenueDate = { ...state.dailyEventsByVenueDate }
      newState.dailyEventsByVenueDate[venueId] = {
        ...state.dailyEventsByVenueDate[venueId],
      }
      newState.dailyEventsByVenueDate[venueId][date] = {
        date,
        events,
        note,
      }
      return newState
    }
    case ActionTypes.BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_SUCCESS: {
      const floorPlan = action.seatingAreaTables
      return { ...state, floorPlan }
    }
    case ActionTypes.BOOK_AVAILABILITY_GET_TIMES_START: {
      const { venue } = action
      const [isLoading, isError] = [true, false]
      const isMultiVenueSearch = state.searchVenues.length > 1
      const availabilityByVenue = {
        ...state.availabilityByVenue,
        [venue.id]: {
          ...resetTimesNewState(isMultiVenueSearch, isLoading, isError),
        },
      }
      return { ...state, availabilityByVenue }
    }
    case ActionTypes.BOOK_AVAILABILITY_GET_TIMES_FAIL: {
      const { venue } = action
      const [isLoading, isError] = [false, true]
      const { isMultiVenueSearch } = state.availabilityByVenue[venue.id] || {}
      const availabilityByVenue = {
        ...state.availabilityByVenue,
        [venue.id]: {
          ...resetTimesNewState(isMultiVenueSearch, isLoading, isError),
        },
      }
      return { ...state, availabilityByVenue }
    }
    case ActionTypes.BOOK_AVAILABILITY_GET_TIMES_NOT_READY: {
      const { venue } = action
      const isLoading = false
      const { isMultiVenueSearch, isTimesLoadError } = state.availabilityByVenue[venue.id] || {}
      const availabilityByVenue = {
        ...state.availabilityByVenue,
        [venue.id]: {
          ...resetTimesNewState(isMultiVenueSearch, isLoading, isTimesLoadError),
        },
      }
      return { ...state, availabilityByVenue }
    }
    case ActionTypes.BOOK_AVAILABILITY_GET_TIMES_SUCCESS: {
      const { venue, availableTimes, internalCutoff } = action
      const { isMultiVenueSearch } = state.availabilityByVenue[venue.id] || {}
      const availabilityByVenue = {
        ...state.availabilityByVenue,
        [venue.id]: {
          availableTimes,
          internalCutoff,
          isMultiVenueSearch,
          isTimesLoading: false,
          isTimesLoadError: false,
        },
      }
      let { selectedTimeSlot } = state
      let { duration } = state
      // if there is a previously selected timeslot, and it's not external availability one
      if (state.previousSelectedTimeSlot && !state.previousSelectedTimeSlot.access_persistent_id) {
        const availableSortOrders = _.map(availabilityByVenue[venue.id].availableTimes, t => t.sort_order)
        const timeSlotIdx = availableSortOrders.indexOf(state.previousSelectedTimeSlot.sort_order)
        if (timeSlotIdx !== -1) {
          selectedTimeSlot = availabilityByVenue[venue.id].availableTimes[timeSlotIdx]
          duration = selectedTimeSlot.duration
        }
      }
      return {
        ...state,
        availabilityByVenue,
        selectedTimeSlot,
        duration,
        previousDuration: state.duration,
        preselectedSortOrder: null,
      }
    }
    case ActionTypes.TOGGLE_BUFFER: {
      return {
        ...state,
        bufferMins: state.bufferMins ? 0 : state.shiftBufferMins,
      }
    }
    case ActionTypes.SET_EXCLUDE_FROM_SHIFT_PACING: {
      return {
        ...state,
        excludeFromShiftPacing: action.value,
      }
    }
    default:
      return state
  }
}

export default bookAvailabilityReducer
